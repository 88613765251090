<template>
  <v-container fluid :class="{'pa-0': !isTab}">
    <v-row dense>
      <v-col cols="12">
        <v-card flat>
          <h2>The BVNE Point System</h2>
          <p>
          Points are awarded for all participating 12U, 14U, 16U, and 18U BVNE juniors tournaments.  The amount of points awarded at each event will be determined by combining our "Base Point Scale" with any approved multipliers for the event. Every event will be subject to the "Team Participation Multiplier" detailed below.
          </p>
          <p>
          Points are awarded to 1st through 9th place for each event. Points are cumulative and rankings are calculated by the sum of all points earned within the previous 365 days.
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card flat class="text-center elevation-1">
          <v-toolbar dense color="color1 color1Text--text">
            <v-toolbar-title>Base Point Scale</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="6" lg="4" xl="3" v-for="table in tables" :key="table.title">
                <v-card>
                  <v-toolbar dense color="color2 color2Text--text">
                    <v-toolbar-title>{{table.title}}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="table.rows"
                      :options.sync="pager"
                      hide-default-footer
                      dense
                      :mobile-breakpoint="0"
                    >

                      <template v-slot:[`item.finish`]="{item}">
                        <span v-if="item.finish < 100000">{{item.finish | ordinal}}</span>
                        <span v-else>All others</span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="text-center">
          <v-toolbar dense color="color1 color1Text--text">
            <v-toolbar-title>Team Participation Multiplier</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="mHeaders"
              :items="multipliers"
              hide-default-footer
              :loading="loading"
              :options="{itemsPerPage: -1}"
              dense
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import uniq from 'lodash.uniq'
// import each from 'lodash.foreach'

export default {
  props: ['isTab'],
  data () {
    return {
      loading1: false,
      loading2: false,
      pointScale: null,
      multipliers: [],
      headers: [
        { text: 'Finish', align: 'left', sortable: false, value: 'finish' },
        { text: 'Points', align: 'left', sortable: false, value: 'points' }
      ],
      pager: { sortBy: ['finish'], itemsPerPage: -1 },
      mHeaders: [
        { text: 'Teams', align: 'left', sortable: false, value: 'description' },
        { text: 'Point Multiplier', align: 'left', sortable: false, value: 'multiplier' }
      ]
    }
  },
  computed: {
    tables () {
      if (!this.pointScale) return []
      const tables = uniq(this.pointScale.map(p => p.division)).sort().reverse()
      return tables.map((table) => {
        return { title: table, rows: this.pointScale.filter(p => p.division === table) }
      })
    },
    loading () {
      return this.loading1 || this.loading2
    }
  },
  methods: {
    getScale () {
      this.loading1 = true
      this.$VBL.getPointScale('BVNE')
        .then((response) => {
          this.pointScale = response.data
          this.loading1 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading1 = false
        })
    },
    getMultipliers () {
      this.loading2 = true
      this.$VBL.getMultipliers('BVNE')
        .then((response) => {
          this.multipliers = response.data
          this.loading2 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading2 = false
        })
    }
  },
  created () {
    console.log('bvne')
    this.getScale()
    this.getMultipliers()
  }
}
</script>

<style>

</style>
